import React from 'react'
import { graphql } from 'gatsby'
import { PrismicLink, PrismicRichText } from '@prismicio/react'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

// Components
import Layout from '../components/layout'
import Seo from '../components/seo'
import SliceList from '../components/sliceList'

export const query = graphql`
  query Product($id: String) {
    prismicProduct(id: { eq: $id }) {
      ...PrismicProductFragment
    }
  }
`

const ProductTemplate = ({ data }) => {
  const page = data.prismicProduct
  const pageData = page.data

  let images = pageData.page_image?.gatsbyImageData
  if (pageData.page_image.gatsbyImageData) {
    images = withArtDirection(getImage(pageData.page_image.gatsbyImageData), [
      {
        media: '(max-width: 768px)',
        image: getImage(pageData.page_image.thumbnails.mobile.gatsbyImageData)
      }
    ])
  }

  return (
    <Layout>
      <div className='relative overflow-hidden bg-gray-900 text-white'>
        <div className='mx-auto max-w-screen-xl flex-row md:flex'>
          <div className='hero relative z-10 bg-gray-900 p-8 px-5 sm:pb-16 md:pb-20 lg:w-full lg:max-w-2xl lg:py-28 xl:pb-32'>
            <svg
              className='absolute inset-y-0 right-0 hidden h-full w-48 translate-x-1/2 text-gray-900 lg:block'
              fill='currentColor'
              viewBox='0 0 100 100'
              preserveAspectRatio='none'
              aria-hidden='true'>
              <polygon points='50,0 100,0 50,100 0,100'></polygon>
            </svg>
            <div className='relative z-20 flex flex-col items-stretch justify-center gap-4 md:max-w-screen-md md:items-start'>
              <div>
                {pageData.status ? (
                  <span className='inline-flex items-center rounded-md bg-pink-100 px-2.5 py-0.5 text-base font-medium text-pink-800'>Sold</span>
                ) : (
                  <span className='inline-flex items-center rounded-md bg-green-100 px-2.5 py-0.5 text-base font-medium text-green-800'>Available</span>
                )}
              </div>
              <PrismicRichText field={pageData.page_title.richText} />
              <div className='prose prose-xl prose-invert'>
                <PrismicRichText field={pageData.page_text.richText} />
              </div>
              <PrismicLink className='button' field={pageData.page_button_link}>
                {pageData.page_button_text}
              </PrismicLink>
            </div>
          </div>
          {pageData.page_image.gatsbyImageData && (
            <div className='lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2'>
              <GatsbyImage className='h-56 w-full object-cover sm:h-72 md:h-96 lg:h-full lg:w-full' image={images} alt={pageData.page_image.alt || ''} />
            </div>
          )}
        </div>
      </div>
      <SliceList slices={pageData.body} />
    </Layout>
  )
}

export default withPrismicPreview(ProductTemplate)

export const Head = ({ data, location }) => {
  const pageData = data.prismicProduct.data
  return (
    <Seo
      pathname={location.pathname}
      title={pageData.meta_title ? pageData.meta_title : pageData.page_title.text}
      description={pageData.meta_description || `Information about ${pageData.page_title.text}`}
      image={pageData.page_image.url}
    />
  )
}
